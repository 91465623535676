<template>
  <div>
    <c-table
      ref="table"
      title="조직도 만들기"
      :columns="grid.columns"
      :editable="editable"
      :data="grid.data"
      selection="multiple"
      rowKey="sopOrgId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" :showLoading="false" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveItem"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-org-mst',
  data() {
    return {
      searchParam: {
      },
      grid: {
        columns: [
          {
            name: 'sopOrgId',
            field: 'sopOrgId',
            label: '조직도 일련번호',
            align: 'left',
            sortable: true,
            style: 'width:20%',
          },
          {
            name: 'parentSopOrgId',
            field: 'parentSopOrgId',
            label: '상위 조직도 일련번호',
            align: 'left',
            type: 'text',
            sortable: true,
            style: 'width:20%',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'textarea',
            sortable: true,
            style: 'width:60%',
          },
        ],
        data: [],
      },
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.org.safetyOrg.list.url;
      this.saveUrl = transactionConfig.sop.org.safetyOrg.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        sopOrgId: uid(),
        jobName: '',
        mobileNo: '',
        userName: '',
        userId: '',
        parentSopOrgId: '',
        remark: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
